<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ this.confirmationPageInfo.Label_Page_Title }}</h1>
    </header>
    <b-container class="top">
      <b-row class="top-row">
        <b-col sm="12" xs="12" class="top-col mb-5">
          <top-nav-search class="top-nav-search" :showDescriptionText="showDescriptionText"
            :backRouteName="`store-cart`" :backLabel="confirmationPageInfo.Label_Back_Description"
            :i18n="translations.components">
          </top-nav-search>
        </b-col>
      </b-row>
      <b-row class="tile-row">
        <b-col sm="12" xs="12" class="tile-col-title">
          <div class="title">
            <div>
              {{
                gideonStoreCartCheckoutType === 'merchandise'
                  ? confirmationPageInfo.Label_CardMerchandiseTitle
                  : confirmationPageInfo.Label_CardScriptureTitle
              }}
            </div>
          </div>
          <div class="category-ddl">
            <b-button variant="secondary" size="sm" class="altButton mr-2" @click="continueShopping()">{{
              confirmationPageInfo.Button_ContinueShopping
              }}</b-button>
          </div>
        </b-col>
        <b-col sm="12" xs="12" class="tile-col-details mb-5">
          <div class="label-strong">{{ confirmationPageInfo.Label_ItemsPurchased }}</div>
          <b-row class="tile-row">
            <b-col sm="3" xs="12" class="tile-col-details mb-5"
              v-for="(item, index) in gideonCheckoutPageInfo.CartItems" :key="index">
              {{ item.vprd_product_name }}<br />
              {{ confirmationPageInfo.Label_Item }} {{ item.vprd_prd_code }}<br />
              {{ confirmationPageInfo.Label_Quantity }} {{ item.shc_quantity }}<br />
              {{ confirmationPageInfo.Label_Price }} ${{ item.vprd_prd_display_price_cp.toFixed(2) }}<br />
            </b-col>
            <b-col sm="3" xs="12" class="tile-col-details mb-5"
              v-if="!!gideonCheckoutPageInfo.ExpeditedShipping && gideonCheckoutPageInfo.ExpeditedShipping.IsExpedited">
              Expedited Shipping<br />
              {{ confirmationPageInfo.Label_Item }} {{ gideonCheckoutPageInfo.ExpeditedShipping.ExpeditedShippingCode
              }}<br />
              {{ confirmationPageInfo.Label_Price }} ${{
                gideonCheckoutPageInfo.ExpeditedShipping.ExpeditedShippingCost.toFixed(2) }}<br />
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="3" xs="12" class="tile-col-details mb-5">
          <div class="label-strong">{{ confirmationPageInfo.Label_Shipping }}</div>
          {{ shippingMemberDisplay }}<br />
          <span v-if="!!selectedShipAddressDisplay">
            {{ selectedShipAddressDisplay.addr_line1 }}<br />
            {{ selectedShipAddressDisplay.address }},
          </span>
        </b-col>
        <b-col sm="3" xs="12" class="tile-col-details mb-5"
          v-if="gideonStoreCartCheckoutType === 'merchandise' && confirmationPageInfo.BillingAddressResults">
          <div class="label-strong">{{ confirmationPageInfo.Label_Billing }}</div>
          {{ confirmationPageInfo.BillingName }}<br />
          {{ confirmationPageInfo.BillingAddressResults.adr_line1 }}<br />
          {{ confirmationPageInfo.BillingAddressResults.adr_city }},
          {{ confirmationPageInfo.BillingAddressResults.adr_state }}
          {{ confirmationPageInfo.BillingAddressResults.adr_postal_code }}<br />
        </b-col>
        <b-col sm="3" xs="12" class="tile-col-details mb-5">
          <div v-if="gideonStoreCartCheckoutType === 'merchandise' && CartTotal > 0.00" class="label-strong">{{
            confirmationPageInfo.Label_CreditCardInfo }}</div>
          <div
            v-if="gideonStoreCartCheckoutType === 'merchandise' && !!gideonStoreSelectedCreditCard && !!gideonStoreSelectedCreditCard.pit_last_four">
            {{ '************' + gideonStoreSelectedCreditCard.pit_last_four }}<br />
            Credit Card on File<br />
            Exp: {{ gideonStoreSelectedCreditCard.pit_expiration }}
          </div>
          <div v-else>
            {{ gideonStoreLastCreditCard.number }}<br />
            {{ gideonStoreLastCreditCard.name }}<br />
            {{ gideonStoreLastCreditCard.expiration }}
          </div>
        </b-col>
        <b-col sm="3" xs="12" class="tile-col-details mb-5">
          <div class="label-strong">{{ confirmationPageInfo.Label_AdditionalInfo }}</div>
          {{ gideonCheckoutPageInfo.AdditionalInfo }}<br />
        </b-col>
        <b-col sm="12" xs="12" class="tile-col-details col-cart-total mb-3">
          <div class="label-strong mb-4" v-if="gideonStoreCartCheckoutType === 'merchandise'">
            {{ this.confirmationPageInfo.Label_OrderTotal }}
            <span class="cartTotal">${{ CartTotal }}</span>
          </div>
          <div class="label-strong mb-4" v-if="gideonStoreCartCheckoutType === 'scripture'">
            {{ this.confirmationPageInfo.Label_CampScriptureFundBalance }}
            <span class="cartTotal">
              {{ new Intl.NumberFormat("en-US", {
                style: "currency", currency: "USD",
              }).format(this.gideonCheckoutPageInfo.CampScriptureFundBalance) }}
            </span>
          </div>
          <div class="details-div">
            <b-button variant="primary" size="sm" class="btn btn-primary btn-top mr-2" @click="viewCart()">
              ({{ this.confirmationPageInfo.Cart[0].Cart_Total_Products }})
              {{ this.confirmationPageInfo.Button_ItemsInCart }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import TopNavSearch from './TopNavSearch.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'store-order-confirmation',
  mixins: [translationMixin],
  data() {
    return {
      storeLPPayload: {
        lang: 'en', // default to english for now
        landingpage: 'Store_PurchaseConfirmation',
        product_type: '',
        orderid: '',
      },
      storeNavPagePayload: {
        lang: 'en', // default to english for now
        landingpage: 'Store_Navigation',
      },
      showDescriptionText: false,
      translations: {},
    }
  },
  components: {
    topNavSearch: TopNavSearch,
  },
  methods: {
    ...mapActions({
      loadStoreConfirmation: 'gideonStore/loadStoreConfirmation',
      loadStoreNavigationPage: 'gideonStore/loadStoreNavigationPage',
      setLoadingStatus: 'menu/setLoadingStatus',
      setStoreCartCheckoutType: 'gideonStore/setStoreCartCheckoutType',
      setStoreLastCreditCard: 'gideonStore/setStoreLastCreditCard',
      setStoreOrderId: 'gideonStore/setStoreOrderId',
      setStoreShippingIndKey: 'gideonStore/setStoreShippingIndKey',
    }),
    async page_load() {
      this.setLoadingStatus(true)
      this.storeNavPagePayload.lang = this.prefCulture
      this.storeLPPayload.lang = this.prefCulture
      this.storeLPPayload.product_type = this.gideonStoreCartCheckoutType
      this.storeLPPayload.orderid = this.gideonStoreOrderId
      await Promise.all([
        await this.loadStoreConfirmation(this.storeLPPayload),
        await this.loadStoreNavigationPage(this.storeNavPagePayload),
        this.getComponentTranslations('store-topnav-search'),
      ]).then((results) => {
        const componentTranslations = results[2]
        this.$set(this.translations, 'components', componentTranslations)

        this.setLoadingStatus(false)
      })
    },
    continueShopping() {
      this.$router.push({ name: 'store-home' })
    },
    viewCart() {
      this.$router.push({ name: 'store-cart' })
    },
  },
  computed: {
    ...mapGetters({
      confirmationPageInfo: 'gideonStore/gideonConfirmationPageInfo',
      gideonCheckoutPageInfo: 'gideonStore/gideonCheckoutPageInfo',
      gideonStoreCart: 'gideonStore/gideonStoreCart',
      gideonStoreCartCheckoutType: 'gideonStore/gideonStoreCartCheckoutType',
      gideonStoreLastCreditCard: 'gideonStore/gideonStoreLastCreditCard',
      gideonStoreSelectedCreditCard: 'gideonStore/selectedCreditCard',
      gideonStoreOrderId: 'gideonStore/gideonStoreOrderId',
      prefCulture: 'user/userPreferredCulture',
      selectedCardOnFile: 'user/selectedCardOnFile',
      selectedShipAddressDisplay: 'gideonStore/selectedShipAddressDisplay',
      shippingMemberDisplay: 'gideonStore/shippingMemberDisplay',
    }),
    CartTotal() {
      let cartTotal = 0
      if (this.gideonCheckoutPageInfo.CartItems.length > 0) {
        const reducer = (accumulator, currentValue) => accumulator + currentValue
        cartTotal = this.gideonCheckoutPageInfo.CartItems.map((x) => x.sum_line_amount).reduce(reducer)
      }
      cartTotal += !!this.gideonCheckoutPageInfo.ExpeditedShipping && this.gideonCheckoutPageInfo.ExpeditedShipping.IsExpedited ? this.gideonCheckoutPageInfo.ExpeditedShipping.ExpeditedShippingCost : 0
      return cartTotal.toFixed(2)
    },
  },
  created() {
    this.page_load()
  },
  beforeRouteLeave(to, from, next) {
    // Reset values before they leave
    let lastCC = {
      name: '',
      number: '',
      expiration: '',
    }
    this.setStoreLastCreditCard(lastCC)
    this.setStoreCartCheckoutType('merchandise')
    this.setStoreOrderId('')
    this.setStoreShippingIndKey('')
    next()
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.page-header {
  p {
    text-align: center;
    margin-top: 20px;
  }
}

.btn-top {
  padding: 8px;
}

.label-strong {
  font-weight: bold;
}

.col-cart-total {
  border-top: 0px solid #747474;
  padding-top: 16px;
}

.cartTotal {
  color: #000000;
}

.tooltip .tooltip-inner {
  max-width: 500px !important;
  width: 450px !important;
}

#body_Checkout_cvc_visa {
  width: 150px;
}

.top-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;

  .top-col {
    .top-nav-search {
      width: 100%;
    }
  }
}

.what-link {
  font-size: 75%;
}

.text-1 {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

.text-2 {
  text-align: center;
  color: $secondary-color;
  width: 70%;
  margin: 0 auto;
}

.special-instructions {
  font-weight: bold;
  color: $primary-color;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;

  .selectMonth {
    width: 75px;
    padding: 8px !important;
  }

  .selectYear {
    width: 100px;
    padding: 8px !important;
  }

  .inputCVC {
    width: 50px;
    padding: 8px !important;
  }
}

.switch-buttons {
  margin: 0 auto;
  text-align: center;

  .merchscriptBtn {
    background-color: #747474;
  }

  .merchscriptBtnSelected {
    background-color: $secondary-color;
  }
}

.altButton {
  background-color: #747474 !important;
  padding: 8px;

  &:hover {
    background-color: $primary-color !important;
  }
}

.tile-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: stretch;
  background-color: #ffffff;
  padding-top: 15px;
  margin-bottom: 25px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.2) !important;

  .tile-col-title {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;

    .category-ddl {
      display: flex;
      flex-direction: row;
      align-content: flex-end;
      justify-content: space-between;

      .btn-go {
        padding: 8px;
      }
    }
  }

  .tile-col-details {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;

    .details-div {
      display: flex;
      flex-direction: row;
      align-content: flex-start;
      justify-content: flex-start;
    }

    .form-div {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: flex-start;
    }

    .tile-row {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: flex-start;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
      border: 0;
    }
  }

  .tile-col {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;

    .tile-col-card {}
  }
}

.tiles {
  margin-bottom: 80px;
}
</style>
